import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "link"];

  connect () {
    this.toggleLink();

    this.boundToggleLink = this.toggleLink.bind(this);
    this.inputTarget.addEventListener("input", this.boundToggleLink);
  }

  disconnect () {
    this.inputTarget.removeEventListener("input", this.boundToggleLink);
  }

  toggleLink () {
    const shouldShow = this.inputTarget.value.length > 0;
    this.linkTarget.classList.toggle("hidden", !shouldShow);
  }

  toggleType (e) {
    this.inputTarget.type === "password" ? this.inputTarget.type = "text" : this.inputTarget.type = "password";
    e.preventDefault();
  }
}
